import {
  message,
  Table,
  Tabs,
  Form,
  DatePicker,
  Button,
  Row,
  Space,
  Select,
  Col,
} from "antd";
import { useEffect, useState } from "react";
import { removePayment } from "../components/api/payments/functions";
import { getDataFromServer, getPayments } from "../components/api/db-api";
import UpsertPaymentForm from "../components/payments/UpsertPaymentForm";
import dayjs from "dayjs";
import { PAYMENT_DATE_FORMAT } from "../CONSTANTS/formats";
import { RecordActionsMenu } from "../components/payments/RecordActionsMenu";
import ModalEdit from "../components/payments/ModalEdit";
const { RangePicker } = DatePicker;

export default function Payments({ user }) {
  const [companies, setCompanies] = useState([]);
  const [payments, setPayments] = useState([]);
  const [isModalEditPaymentOpen, setIsModalEditPaymentOpen] = useState(false);
  const [selectedPay, setSelectedPay] = useState();

  const [form] = Form.useForm();
  useEffect(() => {
    getData();
  }, [user]);

  const getData = async () => {
    const response = await getDataFromServer("get-waapp-companies");
    if (response.status === "error") return message.error(response.message);

    const companiesFromDb = response.data;
    const companies = companiesFromDb.map((company, index) => {
      company.key = index;
      company.label = company.name;
      company.value = company.id;
      return company;
    });

    setCompanies(companies);
  };

  const columns = [
    {
      title: "№",
      key: "№",
      dataIndex: "key",
    },
    {
      title: "Компанія",
      dataIndex: "company",
      key: "company",
      align: "center",
      render: (_, record) => record.company.name,
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
      align: "center",

      sorter: (
        a: { date: string | number | Date },
        b: { date: string | number | Date }
      ) => new Date(a.date) - new Date(b.date),
    },
    {
      title: "Оплата",
      dataIndex: "sum",
      key: "sum",
      align: "center",
      sorter: (a: { sum: number }, b: { sum: number }) => a.sum - b.sum,
      defaultSortOrder: "descend",
    },
    {
      title: "Сума",
      dataIndex: "sum",
      key: "sum",
      align: "center",
      sorter: (a: { sum: number }, b: { sum: number }) => a.sum - b.sum,
      render: (_, record) =>
        record.companyName === "Продажа Техніки" ? record.sum : record.sum / 2,
    },
    {
      title: "Коментар",
      dataIndex: "comment",
      key: "comment",
      align: "center",
    },
    {
      title: "Дії",
      key: "actions",
      render: (_, record) => {
        return (
          <RecordActionsMenu
            record={record}
            onEdit={(record) => {
              setSelectedPay(record);
              setIsModalEditPaymentOpen(true);
            }}
            onDelete={(record) => {
              handleDelete(record);
            }}
          />
        );
      },
    },
  ];

  const handleDelete = async (record) => {
    const response = await removePayment(record.paymentId, record.company.id);
    if (response.status === "error") return message.error(response.message);

    const newData = payments.filter((item) => item.key !== record.key);
    setPayments(newData);
    message.success(response.message);
  };

  const onFinish = async (values: any) => {
    const from = dayjs(values.period[0]).format(PAYMENT_DATE_FORMAT);
    const to = dayjs(values.period[1]).format(PAYMENT_DATE_FORMAT);
    const companyId = values.companyId;
    const responsePayments = await getPayments(from, to, companyId);
    if (responsePayments.status === "error")
      return message.error(responsePayments.message);

    const payments = responsePayments.data.sort((a, b) => a.sum - b.sum);

    const paymentsToTable = payments.map((pay, key) => {
      return {
        ...pay,
        key: key + 1,
        company: { id: pay.companyId, name: pay.companyName },
      };
    });
    setPayments(paymentsToTable);
  };
  const onFinishFailed = (errorInfo: object) => {
    console.log("Failed:", errorInfo);
  };

  const tableSummary = () => {
    let totalAmount = 0;
    let totalPay = 0;
    payments.forEach(({ sum, companyName }) => {
      totalPay += companyName === "Продажа Техніки" ? +sum : +sum / 2;
      totalAmount += +sum;
    });

    return (
      <>
        <Table.Summary.Row
          style={{
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          <Table.Summary.Cell index={0}></Table.Summary.Cell>
          <Table.Summary.Cell index={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={2}></Table.Summary.Cell>
          <Table.Summary.Cell
            index={4}
          >{`Загалом: ${totalAmount} грн`}</Table.Summary.Cell>
          <Table.Summary.Cell
            index={4}
          >{`Сума: ${totalPay} грн`}</Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };

  const items = [
    {
      key: "1",
      label: `Оплати`,
      children: (
        <>
          <Form
            layout="horizontal"
            name="getPaymentForm"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
            style={{ display: "flex", justifyContent: "flex-start" }}
          >
            <Space size="large">
              <Row align="middle" gutter={16} justify="start">
                <Col>
                  <Form.Item
                    label="Період"
                    name="period"
                    rules={[
                      {
                        required: true,
                        message: "Виберіть діапазон дат!",
                      },
                    ]}
                  >
                    <RangePicker />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Компанія"
                    name="companyId"
                    rules={[
                      {
                        required: false,
                        message: "Please select a company!",
                      },
                    ]}
                    style={{ width: "300px" }}
                  >
                    <Select
                      options={companies}
                      style={{ width: "100%" }}
                      showSearch
                      optionFilterProp="label"
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Отримати
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Space>
          </Form>

          <Table
            columns={columns}
            dataSource={payments}
            summary={tableSummary}
            pagination={{ pageSize: 999, hideOnSinglePage: true }}
          ></Table>
        </>
      ),
    },
    {
      key: "2",
      label: `Додати оплату`,
      children: (
        <UpsertPaymentForm
          payments={payments}
          setPayments={setPayments}
          companies={companies}
        ></UpsertPaymentForm>
      ),
    },
  ];

  return (
    <div>
      <Tabs defaultActiveKey="1" items={items}></Tabs>
      <ModalEdit
        payments={payments}
        setPayments={setPayments}
        companies={companies}
        isModalOpen={isModalEditPaymentOpen}
        setIsModalOpen={setIsModalEditPaymentOpen}
        selectedPay={selectedPay}
      ></ModalEdit>
    </div>
  );
}
